import axios from "axios";
import {API_KEY, LIVE, PostAPI} from './postApi';
import {APIurl} from './apisUrl';


export async function LoginAPI(credentials) {
  const baseUrl = LIVE+ APIurl.login + API_KEY;
  return new Promise(function (resolve) {
    const formData = new URLSearchParams();
    formData.append("contact", credentials.contact);
    axios({
      method: "post",
      url: baseUrl,
      data: formData,
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
      });
  });
}

export async function View_Sakh_API(add) {
    const baseUrl = LIVE+ APIurl.view_sakh + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
  
    export async function CurrentCountry_API(add) {
      const baseUrl = LIVE+ APIurl.view_country + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
  
    export async function ViewVisa_API(add) {
      const baseUrl = LIVE+ APIurl.view_visa_type + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
    export async function view_village_allAPI(data) {
      const baseUrl = LIVE+ APIurl.view_village_all + API_KEY;
      return new Promise(function (resolve) {
      const formData = new URLSearchParams();
        formData.append("offset", data.offset);
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (error) {
          });
      });
    }
  
    export async function Marital_API(add) {
      const baseUrl = LIVE+ APIurl.view_marital_status + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
    export async function EducationType_API(add) {
      const baseUrl = LIVE+ APIurl.view_education_type + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
    
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
  
    export async function view_education_sub_type_API(add) {
      const baseUrl = LIVE+ APIurl.view_education_sub_type + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        formData.append("education_id", add.education_id);
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
    export async function Occupation_API(add) {
      const baseUrl =LIVE+ APIurl.view_occupation + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
    
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
  
    export async function Pragati_API(add) {
      const baseUrl = LIVE+ APIurl.view_pragatimandal + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
  
  
    export async function Relation_API(add) {
      const baseUrl = LIVE+ APIurl.view_relation + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
    
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
    export async function AddMemberAPI(data) {
      const baseUrl = LIVE+ APIurl.add_member_by_head_v3 + API_KEY;
      return new Promise(function (resolve) {
        const formData = new FormData();
        formData.append("relation_type", data.relation_type);
        formData.append("surname", data.surname);
        formData.append("first_name", data.first_name);
        formData.append("middle_name", data.middle_name);
        formData.append("last_name", data.last_name);
        formData.append("gender", data.gender);
        formData.append("birthdate", data.birthdate);
        formData.append("age", data.age);
        formData.append("height", data.height);
        formData.append("weight", data.weight);
        formData.append("contact", data.contact);
        formData.append("contact_2", data.contact_2);
        formData.append("email", data.email);
        formData.append("education_id", data.education_id);
        formData.append("education_sub_id", data.education_sub_id);
        formData.append("residence_address", data.residence_address);
        formData.append("village_id", data.village_id);
        formData.append("marital_status", data.marital_status);
        formData.append("occupation", data.occupation);
        formData.append("company_name", data.company_name);
        formData.append("occupation_address", data.occupation_address);
        formData.append("mosal", data.mosal);
        
        formData.append("m_uncle_name", data.m_uncle_name);
        formData.append("blood_group", data.blood_group);
        // formData.append("sakh_id", data.sakh_id);
        formData.append("country_id", data.country_id);
        formData.append("visa_type_name", data.visa_type_name);
      //  formData.append("pragatimandal_id", data.pragatimandal_id);
        formData.append("family_code", localStorage.getItem("family_code"));
        formData.append("matrimonial_status", data.matrimonial_status);
        formData.append("blood_donate_status", data.blood_donate_status);
        formData.append("designation", data.designation);
        formData.append("annual_income", data.annual_income);
        formData.append("female_contact_display", data.female_contact_display);
        formData.append("about_me", data.about_me);
        formData.append("country_code", data.country_code);
        formData.append("member_image", data.member_image);
        formData.append("mother_name",data.mother_name);
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
  
  
    export async function UpdateMemberAPI(edit) {
      const baseUrl = LIVE+ APIurl.update_member_v3 + API_KEY;
      return new Promise(function (resolve) {
        const formData = new FormData();
        formData.append("relation_type",edit.relation_type);
        formData.append("family_member_id",edit.family_member_id);
        formData.append("surname",edit.surname);
        formData.append("first_name",edit.first_name);
        formData.append("middle_name",edit.middle_name);
        formData.append("last_name",edit.last_name);
        formData.append("gender",edit.gender);
        formData.append("birthdate",edit.birthdate);
        formData.append("age",edit.age);
        formData.append("height",edit.height);
        formData.append("weight",edit.weight);
        formData.append("contact",edit.contact);
        formData.append("contact_2",edit.contact_2);
        formData.append("email",edit.email);
        formData.append("education_id",edit.education_id);
        formData.append("education_sub_id",edit.education_sub_id);
        formData.append("residence_address",edit.residence_address);
        formData.append("village_id",edit.village_id);
        formData.append("marital_status",edit.marital_status);
        formData.append("occupation",edit.occupation);
        formData.append("company_name",edit.company_name);
        formData.append("occupation_address",edit.occupation_address);
        formData.append("mosal",edit.mosal);
        formData.append("mother_name",edit.mother_name);
        formData.append("m_uncle_name",edit.m_uncle_name);
        formData.append("blood_group",edit.blood_group);
        // formData.append("sakh_id",edit.sakh_id);
        formData.append("country_id",edit.country_id);
        formData.append("visa_type_name",edit.visa_type_name);
        // formData.append("pragatimandal_id",edit.pragatimandal_id);
        formData.append("family_code", localStorage.getItem("family_code"));
        formData.append("matrimonial_status",edit.matrimonial_status);
        formData.append("blood_donate_status",edit.blood_donate_status);
        formData.append("designation",edit.designation);
        formData.append("annual_income",edit.annual_income);
        formData.append("female_contact_display",edit.female_contact_display);
        formData.append("about_me",edit.about_me);
        formData.append("country_code",edit.country_code);
        formData.append("member_image",edit.member_image);
        if (edit.photo != null) {
          formData.append("photo",edit.photo);
        }
        else{
          formData.append("member_image_old",edit.member_image_old);
        }
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }
  

    export async function RegistrationApi(add) {
        const baseUrl = LIVE+ APIurl.register + API_KEY;
       return new Promise(function (resolve) {
           const formData = new URLSearchParams();
           formData.append('surname', add.surname);
           formData.append('first_name', add.first_name);
           formData.append('middle_name', add.last_name);
           formData.append('last_name', add.middle_name);
           formData.append('contact', add.contact);
           formData.append('email', add.email);
           formData.append('gender', add.gender);
           formData.append('residence_address', add.residence_address);
           formData.append('village_id', add.village_id);
          //  formData.append('sakh_id', add.sakh_id);
           formData.append('country_id', add.country_id);
          //  formData.append('country_code', add.country_code);
           axios({
               method: 'post',
               url: baseUrl,
               data: formData
           })
               .then(function (response) {
                   resolve(response.data);
               })
               .catch(function (error) {
               });
       });
   }

   export async function Add_business_detailsAPI(add) {
    const baseUrl = LIVE+ APIurl.add_business_details + API_KEY;
    console.log('addadd ', add)
    return new Promise(function (resolve) {
      const formData = new FormData();
      formData.append("business_name", add.business_name);
      formData.append("concern_person", add.concern_person);
      formData.append("address", add.address);
      formData.append("contact_1", add.contact_1);
      formData.append("contact_2", add.contact_2);
      formData.append("website", add.website);
      formData.append("category_id", add.category_id);
      formData.append("city_id", add.city_id);
      formData.append("description", add.description);
      formData.append("pin", add.pin);
      formData.append("country_id", add.country_id);
      formData.append("email", add.email);
      formData.append("location", add.location);
      formData.append("business_facebook", add.business_facebook);
      formData.append("business_youtube", add.business_youtube);
      formData.append("business_telegram", add.business_telegram);
      formData.append("business_insta", add.business_insta);
      formData.append("business_linkedin", add.business_linkedin);
      formData.append("latitude", 0);
      formData.append("longitude", 0);
      formData.append("image_1", add.image_1);
      formData.append("business_catalogue", add.business_catalogue);
      formData.append(
        "family_member_id",
        localStorage.getItem("family_member_id")
      );
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {});
    });
  }

  export async function UpdateImage2(id) {
    const baseUrl = LIVE+ APIurl.update_business_image_2 + API_KEY;
    return new Promise(function (resolve, reject) {
      const formData = new FormData();
      formData.append("business_id", id?.id);
      formData.append("image_2", id?.bussImage);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {});
    });
  }
  export async function UpdateImage3(edit) {
    const baseUrl = LIVE+ APIurl.update_business_image_3 + API_KEY;
    return new Promise(function (resolve, reject) {
      const formData = new FormData();
      formData.append("business_id", edit?.id);
      formData.append("image_3", edit?.bussImage);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {});
    });
  }

  export async function Add_business_UpdateAPI(edit) {
    const baseUrl = LIVE+ APIurl.update_business_details + API_KEY;
    console.log('111editedit ', edit);
    return new Promise(function (resolve) {
      const formData = new FormData();
      formData.append("business_id", edit.business_id);
      formData.append("business_name", edit.business_name);
      formData.append("concern_person", edit.concern_person);
      formData.append("address", edit.address);
      formData.append("contact_1", edit.contact_1);
      formData.append("contact_2", edit.contact_2);
      formData.append("website", edit.website);
      formData.append("category_id", edit.category_id);
      formData.append("city_id", edit.city_id);
      formData.append("description", edit.description);
      formData.append("pin", edit.pin);
      formData.append("country_id", edit.country_id);
      formData.append("email", edit.email);
      formData.append("location", edit.location);
      formData.append("business_facebook", edit.business_facebook);
      formData.append("business_youtube", edit.business_youtube);
      formData.append("business_telegram", edit.business_telegram);
      formData.append("business_insta", edit.business_insta);
      formData.append("business_linkedin", edit.business_linkedin);
      formData.append("image_1", edit.image_1);
      formData.append("business_catalogue", edit.business_catalogue);
      // if (edit.image_1 != null) {
      //   formData.append("image_1", edit.image_1);
      // } else {
      //   formData.append("image_1_old", edit.image_1_old);
      // }
      // if (edit.business_catalogue != null) {
      //   formData.append("business_catalogue", edit.business_catalogue);
      // } else {
      //   formData.append("business_catalogue_old", edit.business_catalogue_old);
      // }
      formData.append(
        "family_member_id",
        localStorage.getItem("family_member_id")
      );
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {});
    });
  }

  export async function City_API(add) {
    const baseUrl = LIVE+ APIurl.view_city_all + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {});
    });
  }
  export async function view_business_categoryAPI(add) {
    const baseUrl = LIVE+ APIurl.view_business_category + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      formData.append("education_id", add.education_id);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {});
    });
  }

  export async function NewsAPI(data) {
    const baseUrl = LIVE+ APIurl.view_news + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("offset", data.offset);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function PrivancyAPI(data) {
    const baseUrl = LIVE+ APIurl.privacy_policy + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("type", data.type);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
          
        })
        .catch(function (error) {
        });
    });
  }

  export async function TermsAPI(data) {
    const baseUrl = LIVE+ APIurl.terms_condition + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("type", data.type);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
          
        })
        .catch(function (error) {
        });
    });
  }

  export async function BesanuAPI(data) {
    const baseUrl = LIVE+ APIurl.view_besanu + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("offset", data.offset);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
          
        })
        .catch(function (error) {
        });
    });
  }

  export async function DonersAPI(data) {
    const baseUrl = LIVE+ APIurl.view_donors_list + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("donor_type_id", data.donor_type_id);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
          
        })
        .catch(function (error) {
        });
    });
  }

  export async function SliderAPI(data) {
    const baseUrl = LIVE+ APIurl.view_slider + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("offset", data.offset);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
          
        })
        .catch(function (error) {
        });
    });
  }

  
  export async function MyBusinessAPI(data) {
    const baseUrl = LIVE+ APIurl.view_business_details_filter + API_KEY;
  
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      formData.append("business_name", data.business_name);
      formData.append("category_id", data.category_id);
      formData.append("business_owner_name", data.business_owner_name);
      formData.append("city_id", data.city_id);
      formData.append("offset", data.offset);
      formData.append("contact", data.contact);
      formData.append("country_id", data.country_id);
      formData.append("family_member_id", data.family_member_id);    
      formData.append("pin", data.pin);
      formData.append("approve_status", data.approve_status);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function MatrimonailMemberAPI(credentials) {
    const baseUrl = LIVE+ APIurl.view_matrimonial_members + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      formData.append("offset", credentials.offset)
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {});
    });
  }

  export async function Bloodgroup_API(add) {
    const baseUrl = LIVE+ APIurl.view_blood_group + API_KEY;

    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }


  export async function ViewBloodsListAPI(data) {
    const baseUrl =  LIVE+ APIurl.view_blood_donor_members + API_KEY;

    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("blood_group", data.blood_group);
      formData.append("offset", data.offset);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function App_detailAPI(add) {
    const baseUrl = LIVE+ APIurl.view_app_details + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      formData.append("type", add.type);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function  View_members_by_family_codeAPI(data) {
 
    const baseUrl = LIVE+ APIurl.view_members_by_family_code + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      formData.append("offset", data.offset);
      formData.append("family_code", data.family_code);
     
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function view_members_advance_searchAPI(data) {
    const baseUrl = LIVE+ APIurl.view_members_advance_search_v4 + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      formData.append("name", data.name);
      formData.append("village_id", data.village_id);
      formData.append("gender", data.gender);
      formData.append("from_age", data.from_age);
      formData.append("to_age", data.to_age);
      formData.append("marital_status", data.marital_status);
      formData.append("blood_group", data.blood_group);
      formData.append("education_id", data.education_id);
      formData.append("education_sub_id", data.education_sub_id);
      formData.append("country_id", data.country_id);
      formData.append("city", data.city);
      formData.append("matrimonial_status", data.matrimonial_status);
      formData.append("offset", data.offset);
      formData.append("contact", data.contact);
      formData.append("residence_address", data.residence_address);
      formData.append("sakh_id", data.sakh_id);
      formData.append("occupation", data.occupation);
      formData.append("visa_type_name", data.visa_type_name);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }
  
  export async function ViewCommitteeMembersAPI(data) {
    const baseUrl =LIVE+ APIurl.committee_all + API_KEY;

    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
    //   formData.append("offset", data.offset);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function ViewBhagini_SansthasAPI(credentials) {
    const baseUrl =LIVE+ APIurl.view_committee_members + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
      formData.append("committee_pragatimandal_id", credentials.committee_pragatimandal_id);
  
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function DonorAPI(data) {
    const baseUrl = LIVE+ APIurl.view_donors_by_type + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
    formData.append("donor_type_id", data.donor_type_id);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function ProfilepageyAPI(data) {
    const baseUrl = LIVE+ APIurl.view_members_by_id + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
    formData.append("family_member_id",localStorage.getItem("family_member_id"));
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function PhotoGalleryAPI(data) {
    const baseUrl = LIVE+ APIurl.view_photo_gallery + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("offset", data.offset);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function VideoGalleryAPI(data) {
    const baseUrl = LIVE+ APIurl.view_video_gallery + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("offset", data.offset);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function InquiryAPI(data) {
    const baseUrl = LIVE+ APIurl.add_inquiry + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("inquiry_title", data.inquiry_title);
      formData.append("inquiry_message",data.inquiry_message);
      formData.append("contact",data.contact);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }
  
  
  export async function PaynowAPI(data) {
    const baseUrl = LIVE+ APIurl.paynow_JM_member + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("member_id", data.member_id);
      formData.append("reg_date", data.reg_date);
      formData.append("reg_amount", data.reg_amount);
      formData.append("regi_type", data.regi_type);
      formData.append("payment_mode", data.payment_mode);
      formData.append("remarks", data.remarks);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
          
        })
        .catch(function (error) {
        });
    });
  }

  export async function about_JMAPI(add) {
    const baseUrl = LIVE+ APIurl.about_JM + API_KEY;
    return new Promise(function (resolve) {
      const formData = new URLSearchParams();
     
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function view_donationAPI(data) {
    const baseUrl = LIVE+ APIurl.view_donation + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("jm_member_id", data.jm_member_id);
      formData.append("payment_status", data.payment_status);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function View_All_JM_Member_API(data) {
    const baseUrl = LIVE+ APIurl.All_JM_member_list + API_KEY;
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        formData.append("offset", data.offset);
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          
          })
          .catch(function (error) {
          });
      });
    }

    export async function view_recent_donorsAPI(data) {
      const baseUrl = LIVE+ APIurl.recent_donors + API_KEY;
      return new Promise(function (resolve) {
      const formData = new URLSearchParams();
        
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (error) {
          });
      });
    }

    

    export async function view_donation_categoryAPI(data) {
      const baseUrl = LIVE+ APIurl.view_donation_category + API_KEY;
      return new Promise(function (resolve) {
      const formData = new URLSearchParams();
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (error) {
          });
      });
    }

    export async function View_donation_listAPI(data) {
      const baseUrl = LIVE+ APIurl.donation_list + API_KEY;
    
      return new Promise(function (resolve) {
        const formData = new URLSearchParams();
        formData.append("offset", data.offset);
        formData.append("contact", data.contact);
        formData.append("village_id", data.village_id);
        formData.append("donation_category", data.donation_category);
        formData.append("user_id", data.user_id);
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
          });
      });
    }

    export async function View_List_Of_Villages_API(data) {
      const baseUrl =  
      // LIVE+ APIurl.view_education_type + API_KEY;
       LIVE+ APIurl.view_total_members_by_village + API_KEY;
      return new Promise(function (resolve) {
      const formData = new URLSearchParams();
        axios({
          method: "post",
          url: baseUrl,
          data: formData,
        })
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (error) {
          });
      });
    }

    
  export async function view_Family_head_List_allAPI(data) {
    const baseUrl = LIVE+ APIurl.view_members_by_city + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();
      formData.append("offset", data.offset);
      formData.append("village_id", data.village_id);
      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
        });
    });
  }

  export async function CountAPI(data) {
    const baseUrl = LIVE+ APIurl.dashboard_count + API_KEY;
    return new Promise(function (resolve) {
    const formData = new URLSearchParams();

      axios({
        method: "post",
        url: baseUrl,
        data: formData,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        });
    });
  }
  
  