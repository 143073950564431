import React from "react";
import "../styles/Footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import playstoreIcon from "../assets/logo/playstore.png";
import appstoreIcon from "../assets/logo/appstore.png";
import { Link } from "react-router-dom";
import { Strings } from "../Common/String";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="social-icon-fb"
        style={{ color: '#1877F2' }}>
          <FacebookIcon />
        </a>
        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="social-icon-insta" style={{ color: '#C13584' }}>
          <InstagramIcon />
        </a>
        <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
          <img src={playstoreIcon} alt="Play Store" className="store-icon" />
        </a>
        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src={appstoreIcon} alt="App Store" className="store-icon" />
        </a>
      </div>
      &nbsp;&nbsp;
      <span style={{ marginTop: '3px', fontWeight: 800 }}> | </span>
      &nbsp;
      <div className="links">
        <Link to="/privacypolicy" className="footer-link">{Strings.PrivacyPolicy}</Link>&nbsp;&nbsp;&nbsp;
        <Link to="/termsconditions" className="footer-link">{Strings.TermsConditions}</Link>
      </div>
      &nbsp;
      <span style={{ marginTop: '5px', fontWeight: 800 }}> | </span>
      &nbsp;
      <div className="footer-text">
        {Strings.Copyright}
      </div>
    </footer>
  );
};

export default Footer;
